import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrumbs: [], // Array of breadcrumb objects
  removedBreadcrumbs: []
};
const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    addNewBreadcrumbs: (state, action) => {
      const { title, url } = action.payload;
      const tab = action.payload.tab || "";
      const type = action.payload.type || "";
      const accountName = action.payload.account_Name || "";
      const accountId = action.payload.accountId || "";
      const urlParts = url.split('/');
      const lastPartOfUrl = urlParts[urlParts.length - 1];
      const existingBreadcrumbIndex = state.breadcrumbs ? state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      ) : -1;
      if (existingBreadcrumbIndex === -1) {
        if (lastPartOfUrl === "Details") {
          state.breadcrumbs.push({ title, url, tab, type, accountName, accountId });
        } else {

          const similarBreadcrumbIndex = state.breadcrumbs.findIndex((breadcrumb) => {
            const breadcrumbUrlParts = breadcrumb.url.split('/');
            const lastPartOfBreadcrumbUrl = breadcrumbUrlParts[breadcrumbUrlParts.length - 1];
            return breadcrumbUrlParts.slice(0, -1).join('/') === urlParts.slice(0, -1).join('/')
              && lastPartOfBreadcrumbUrl !== "Details";
          });

          if (similarBreadcrumbIndex !== -1) {
            state.breadcrumbs[similarBreadcrumbIndex] = { title, url, tab, type, accountName, accountId };
          } else {
            state.breadcrumbs.push({ title, url, tab, type, accountName, accountId });
          }
        }
      } else {
        state.breadcrumbs = state.breadcrumbs.slice(0, existingBreadcrumbIndex + 1);
      }
    },
    updateBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    handleBreadcrumbsClick: (state, action) => {
      const { accountName, breadcrumbsFromStore } = action.payload;
      function areStringsEqual(str1, str2) {

        function cleanString(str) {
          return str
            .trim()               // Remove leading and trailing spaces
            .replace(/\s+/g, ''); // Remove all spaces (not just extra spaces between words)
        }
        const cleanedStr1 = cleanString(str1);
        const cleanedStr2 = cleanString(str2);

        return cleanedStr1 === cleanedStr2;
      }
      const name = accountName
      const detailsIndex = breadcrumbsFromStore.findIndex(breadcrumb => areStringsEqual(breadcrumb.title, name + " Details"))
      const index = detailsIndex;
      if (detailsIndex !== -1 && detailsIndex < breadcrumbsFromStore.length - 1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
      }
    },
    removeBreadcrumb: (state, action) => {
      const urlToRemove = action.payload;
      const breadcrumbsList = state.breadcrumbs;
      const breadcrumbToRemove = breadcrumbsList.find(
        (breadcrumb) => breadcrumb.url === urlToRemove
      );
      if (breadcrumbToRemove) {
        if (!state.removedBreadcrumbs.some(bc => bc.url === breadcrumbToRemove.url)) {


          state.removedBreadcrumbs.push(breadcrumbToRemove);
        }

      } else {
        console.log(`Breadcrumb with URL ${urlToRemove} not found.`);
      }

      state.breadcrumbs = state.breadcrumbs.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );


    },
    removeBackBreadcrumb: (state, action) => {
      const urlToRemove = action.payload;
      state.breadcrumbs?.pop();
    },
    restoreBreadcrumb: (state, action) => {
      const breadcrumbToRestore = action.payload
      const removeList1 = state.removedBreadcrumbs
      state.breadcrumbs?.pop();
      if (breadcrumbToRestore) {
        state.breadcrumbs.push(breadcrumbToRestore);
        state.removedBreadcrumbs = state.removedBreadcrumbs.filter(bc => bc.url !== action.payload);
      }
      const removeList = state.removedBreadcrumbs

    },
    // Clear all breadcrumbs
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
      state.removedBreadcrumbs = []
    },
  },
});

export const {
  addNewBreadcrumbs,
  updateBreadcrumbs,
  handleBreadcrumbsClick,
  removeBreadcrumb,
  clearBreadcrumbs,
  removeBackBreadcrumb,
  restoreBreadcrumb
  ,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedTab: '',
    siteActiveTab: 'Details',
    contactActiveTab: "Details",
    inpersonActiveTab: "Details",
    editAccountData: {},
    activeTab: {},
    editSiteData: {},
    editContactDetails: {},
    editPopDetails: {},
    active_tab_list: {
        account: "",
        site: "",
        contact: "",
        aed: "",
        aed_service_check: "",
        accessories: "",
        pops: ""
    }
}


export const TabSlice = createSlice({
    name: 'tab',
    initialState,
    reducers: {
        selecteTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        handleTabfunction: (state, action) => {
            try {
                const tab_type = action.payload.type;
                const tab_value = action.payload.value;

                const Defaultobj = {
                    account: "",
                    site: "",
                    contact: "",
                    aed: "",
                    aed_service_check: "",
                    accessories: "",
                    pops: ""
                }


                if (state?.active_tab_list?.hasOwnProperty(tab_type)) {
                    // Update value if the tab_type exists in state.active_tab_list
                    state.active_tab_list[tab_type] = tab_value;
                } else if (Defaultobj.hasOwnProperty(tab_type)) {
                    // Check if tab_type exists in Defaultobj and update the object
                    Defaultobj[tab_type] = tab_value;

                    // Create a copy of Defaultobj to avoid mutating the original
                    state.active_tab_list = { ...Defaultobj };
                } else {
                    console.log("All Tabs conditions gone False", tab_type, Defaultobj?.[tab_type], state?.active_tab_list?.[tab_type]);
                }

            } catch (e) {
                console.log("Having Error While Enter The Text: " + e.message)
            }
        },
        setSiteActiveTab: (state, action) => {
            state.siteActiveTab = action.payload;
        },
        setContactActiveTab: (state, action) => {
            state.contactActiveTab = action.payload;
        },

        setInpersonActiveTab: (state, action) => {
            state.inpersonActiveTab = action.payload;
        },

        setPopActiveTab: (state, action) => {
            state.popActiveTab = action.payload;
        },

        setEditAccountData: (state, action) => {
            state.editAccountData = action.payload;
        },

        editSiteDataAdd: (state, action) => {
            state.editSiteData = action.payload;
        },

        setEditContactDetails: (state, action) => {
            state.editContactDetails = action.payload;
        },

        setEditPopDetails: (state, action) => {
            state.editPopDetails = action.payload;
        },

    }
});

export const { selecteTab, setSiteActiveTab, setContactActiveTab, setInpersonActiveTab, setPopActiveTab, setEditAccountData,
    handleTabfunction,
    editSiteDataAdd,
    setEditContactDetails,
    setEditPopDetails,
} = TabSlice.actions;

export default TabSlice.reducer;
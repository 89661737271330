import React, { useEffect } from "react";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
// import { Alert } from 'react-bootstrap'
// import { Snackbar } from '@mui/material'
import SubHeading from "../../dashboard/components/header/SubHeading";
// import { Box } from 'devextreme-react'
import { DecryptToken, truncateText } from "../../../common/helper/BasicFn";
import Moment from "react-moment";
import { Alert, Box, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addNewBreadcrumbs,
  clearBreadcrumbs,
} from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
function SideEquipmentList() {
  const user = DecryptToken();
  const [openModel, setOpenModel] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let is_user = false;
  const handleAedClick = (item) => {
    if (user.user_type > 1) {
      is_user = true;
      let permissions = localStorage.getItem("permissions");
      let permissionsArr = permissions.split(",");
      if (permissionsArr.includes("aed-details")) {
        // is_aed_details = true;
        handleDetailsDispatch(
          item.aed_id,
          item.serial_number,
          item.account_id,
          item.account_name
        );
        navigate("/user/aed-details/" + item?.aed_id + "/Details", {
          state: { is_user: true, privileges: permissionsArr },
        });
      }
    } else {
      handleDetailsDispatch(
        item.aed_id,
        item.serial_number,
        item.account_id,
        item.account_name
      );
      navigate("/account/aed-details/" + item?.aed_id + "/Details");
    }
  };
  useEffect(() => {
    // Dispatch clearBreadcrumbs action when component is mounted
    dispatch(clearBreadcrumbs());
  }, [dispatch]);
  const handleDetailsDispatch = (
    aedId,
    serial_number,
    accountId,
    accountName
  ) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Aed Listing";
      // BreadCrumbsObject.tab = "Details";
      // BreadCrumbsObject.type = "site";
      dispatch(
        addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/Admin/Aed-listing" })
      );
    };
    handleDispatch();
    BreadCrumbsObject.title = serial_number + " Details";
    BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = "site";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed-details/${aedId}/Details`,
      })
    );
  };
  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModel(false);
  };

  const headerColumns = [
    {
      Header: "AED Brand / Model",
      accessor: "AED_brands",
      Cell: ({ row }) => (
        <span
          className="link"
          style={{ textDecoration: "none" }}
          onClick={() => {
            handleAedClick(row.original);
          }}
          title={
            row.original.AED_brands?.length > 15
              ? row.original.AED_brands
              : undefined // Tooltip only if the text is truncated
          }
        >
          {truncateText(row.original.AED_brands, 15)}
        </span>
      ),
      width: "18%",
    },
    {
      Header: "Serial #",
      accessor: "serial_number",
      Cell: ({ row }) => (
        <span
          className="link"
          style={{ textDecoration: "none" }}
          onClick={() => handleAedClick(row.original)}
          title={
            row.original.serial_number?.length > 20
              ? row.original.serial_number
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.serial_number, 20)}
        </span>
      ),
    },
    {
      Header: "Last Check",
      accessor: "last_check_date",
      Cell: ({ row }) => (
        <Moment
          date={row.original.last_check_date}
          format="MM/DD/YYYY h:mm:ss"
        />
      ),
    },
    {
      Header: "Last Service",
      accessor: "last_service_date",

      Cell: ({ row }) => (
        <Moment
          date={row.original.last_service_date}
          format="MM/DD/YYYY h:mm:ss"
        />
      ),
    },

    {
      Header: "Account",
      accessor: "account_name",
      Cell: ({ row }) => (
        <span
          title={
            row.original.account_name?.length > 20
              ? row.original.account_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.account_name, 20)}
        </span>
      ),
    },
    {
      Header: "Site Name",
      accessor: "account_site_name",
      Cell: ({ row }) => (
        <span
          title={
            row.original.account_site_name?.length > 20
              ? row.original.account_site_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.account_site_name, 20)}{" "}
        </span>
      ),
    },
  ];
  return (
    <>
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4">
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"AED Listing"}
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          <Box className="py-4">
            <NewGlobalTable
              method={Number(user?.user_type) === 0 ? "GET" : "POST"}
              isSearchable={true}
              isCache={true}
              pageSize={60}
              apiEndPoint={
                Number(user?.user_type) === 0
                  ? "aed/equipment-list"
                  : "user/account-equipment-list"
              }
              keyAPiName={Number(user?.user_type) === 0 ? "resultData" : "aed"}
              headerColumns={headerColumns}
            />
          </Box>
        </div>
      </div>
    </>
  );
}

export default SideEquipmentList;

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
} from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { MoreVertical, GripVertical } from "lucide-react";
import AccountsCard from "../components/cards/accounts";
import SitesCard from "../components/cards/sites";
import ContactsCard from "../components/cards/contacts";
import AedsCard from "../components/cards/aeds";
import AccessoriesCard from "../components/cards/accessories";
import AedAssignedCard from "../components/cards/aedsAssigned";
import AedCheckCard from "../components/cards/aedCheck";
import LineGraph from "../components/LineGraph/LineGraph";
import DashBarGraph from "../components/BarChart/BarGraph";
import SupportHalfPieChart from "../components/PieCharts/SupportHalfPieChart";
import AedOwnedPieChart from "../components/PieCharts/aedOwnedPieChart";
import AedDistributionPieChart from "../components/PieCharts/aedDistributionPieChart";
import DeliquentCheckHalfPieChart from "../components/PieCharts/deliquentCheckHalfPieChart";
import {
  checkLabelsLength,
  updateDragDrpOrder,
  updateSectionVisibility,
} from "../components/Services";
import "../css/chart.scss";
import { Skeleton } from "@mui/material";
import { DecryptToken } from "../../../common/helper";
import { useDndScrolling } from "react-dnd-scrolling";

const componentMap = {
  AccountsCard,
  SitesCard,
  ContactsCard,
  AedsCard,
  AccessoriesCard,
  AedAssignedCard,
  AedCheckCard,
  SupportHalfPieChart,
  DeliquentCheckHalfPieChart,
  AedOwnedPieChart,
  AedDistributionPieChart,
  LineGraph,
  DashBarGraph,
};

const DraggableItem = memo(
  ({
    item,
    index,
    moveItem,
    sections,
    setSections,
    sectionId,
    onColorChange,
    loading,
    setIsDragging,
  }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "DASHBOARD_ITEM",
      item: () => {
        setIsDragging(true);
        return {
          id: item.id,
          index,
          sectionId,
          originalItem: item,
        };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        setIsDragging(false);
      },
    });

    const [, drop] = useDrop({
      accept: "DASHBOARD_ITEM",
      hover: (draggedItem, monitor) => {
        if (!monitor.isOver({ shallow: true })) return;

        const dragIndex = draggedItem.index;
        const hoverIndex = index;
        const sourceSectionId = draggedItem.sectionId;
        const targetSectionId = sectionId;

        if (dragIndex === hoverIndex && sourceSectionId === targetSectionId) {
          return;
        }

        moveItem(
          dragIndex,
          hoverIndex,
          sourceSectionId,
          targetSectionId,
          draggedItem.originalItem
        );

        draggedItem.index = hoverIndex;
        draggedItem.sectionId = targetSectionId;
      },
    });

    const opacity = isDragging ? 0.4 : 1;

    const renderContent = () => {
      if (loading) {
        return (
          <Skeleton
            count={1}
            width={"100%"}
            height={250}
            style={{ marginBottom: "2px" }}
          />
        );
      }

      const Component = item.componentName
        ? componentMap[item.componentName]
        : null;

      const restrictedComponents = [
        "aedsAssigned",
        "aedChecks",
        "DeliquentCheckHalfPieChart",
        "AedOwnedPieChart",
        "AedDistributionPieChart",
      ];

      const user = DecryptToken();
      const userType = Number(user?.user_type);

      if (userType === 0) {
        if (restrictedComponents.includes(item.id)) {
          return null; // Don't render the component if it's in the restricted list
        }
      }

      if (Component) {
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Component sections={sections} setSections={setSections} />
          </div>
        );
      }
    };

    const restrictedComponents = [
      "aedsAssigned",
      "aedChecks",
      "DeliquentCheckHalfPieChart",
      "AedOwnedPieChart",
      "AedDistributionPieChart",
    ];

    return (
      <>
        {Number(item?.isVisible) === 1 ? (
          // && Number(checkLabelsLength(item)) === 0 ? (
          <div
            ref={(node) => drag(drop(node))}
            style={{
              opacity,
              width: "100%",
              backgroundColor: !loading
                ? item.type == "chart"
                  ? ""
                  : item.color
                : "",
              padding: "10px",
              margin: "5px",
              borderRadius: "5px",
              height: "100%",
              position: "relative",
            }}
          >
            {renderContent()}
          </div>
        ) : null}
      </>
    );
  }
);

const Section = memo(
  ({
    section,
    index,
    moveItem,
    moveSection,
    sections,
    setSections,
    loading,
    isDragging,
    setIsDragging,
  }) => {
    const isEmpty =
      section &&
      Array.isArray(section.items) &&
      section?.items?.filter((item) => Number(item?.isVisible) === 1).length ===
        0;

    const [{ isDragging: isSectionDragging }, drag] = useDrag({
      type: "DASHBOARD_SECTION",
      item: { type: "DASHBOARD_SECTION", id: section.id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ isOver }, drop] = useDrop({
      accept: ["DASHBOARD_ITEM", "DASHBOARD_SECTION"],
      hover(item, monitor) {
        if (item.type === "DASHBOARD_SECTION") {
          if (item.index === index) return;
          moveSection(item.index, index);
          item.index = index;
        }
      },
      drop: (item, monitor) => {
        if (monitor.getItemType() === "DASHBOARD_ITEM") {
          if (item.sectionId !== section.id) {
            const hoverIndex = 0;
            moveItem(
              item.index,
              hoverIndex,
              item.sectionId,
              section.id,
              item.originalItem
            );
          }
        }
        return undefined;
      },
    });

    const getColumnWidth = (itemCount, sectionId) => {
      if (sectionId === "section3") return 6;
      const totalColumns = 12;
      return Math.floor(totalColumns / itemCount);
    };

    // Updated section visibility logic
    const sectionStyle = {
      opacity: 1,
      minHeight: "100px",
      border: `1px solid #ccc`,
      margin: "10px 0",
      padding: "10px",
      transition: "all 0.3s ease",
      // Show empty sections only during drag
      display: isEmpty && !isDragging ? "none" : "block",
      // Keep position in document flow even when hidden
      visibility: isEmpty && !isDragging ? "hidden" : "visible",
      height: isEmpty && !isDragging ? "0" : "auto",
    };

    const dragDropRef = (node) => {
      drag(drop(node));
    };

    return (
      <div ref={dragDropRef} style={sectionStyle}>
        <Row className="g-2">
          {/* {JSON.stringify(section)} */}
          {section &&
            section?.items?.map((item, itemIndex) => (
              <Col
                key={item.id}
                xs={
                  item.type === "chart"
                    ? getColumnWidth(section?.items?.length, section.id)
                    : ""
                }
                className="d-flex checkby-d"
              >
                <DraggableItem
                  item={item}
                  index={itemIndex}
                  moveItem={moveItem}
                  sections={sections}
                  setSections={setSections}
                  sectionId={section.id}
                  loading={loading}
                  setIsDragging={setIsDragging}
                />
              </Col>
            ))}
        </Row>
      </div>
    );
  }
);

const ScrollableContainer = ({ children, isDragging }) => {
  const scrollRef = useRef(null);
  const [mousePosition, setMousePosition] = useState({ y: 0 });
  const scrollingRef = useRef(false);
  const mouseMoveListenerRef = useRef(null);

  useEffect(() => {
    if (!isDragging) return;

    const handleMouseMove = (e) => {
      // Get the actual mouse position relative to the page
      const mouseY = e.clientY || e.pageY || (e.touches && e.touches[0].pageY);

      // Update state only if mouseY is not 0 or undefined
      if (mouseY) {
        setMousePosition({ y: mouseY });
      }
    };

    // Store the listener reference
    mouseMoveListenerRef.current = handleMouseMove;

    // Add event listener with capture phase
    document.addEventListener("mousemove", handleMouseMove, {
      passive: true,
      capture: true,
    });
    // Add touch event support for mobile
    document.addEventListener("touchmove", handleMouseMove, {
      passive: true,
      capture: true,
    });

    const handleScroll = () => {
      if (!scrollRef.current || !isDragging) return;

      const container = scrollRef.current;
      const containerRect = container.getBoundingClientRect();
      const mouseY = mousePosition.y;

      const scrollZoneSize = 150;
      const topTrigger = containerRect.top + scrollZoneSize;
      const bottomTrigger = containerRect.bottom - scrollZoneSize;
      let scrollSpeed = 0;
      const maxSpeed = 25;

      if (mouseY < topTrigger) {
        const distance = topTrigger - mouseY;
        scrollSpeed = -(distance / scrollZoneSize) * maxSpeed;
      } else if (mouseY > bottomTrigger) {
        const distance = mouseY - bottomTrigger;
        scrollSpeed = (distance / scrollZoneSize) * maxSpeed;
      }

      if (scrollSpeed !== 0) {
        const currentScroll = container.scrollTop;
        const newScrollTop = currentScroll + scrollSpeed;
        const maxScroll = container.scrollHeight - container.clientHeight;

        if (newScrollTop >= 0 && newScrollTop <= maxScroll) {
          container.scrollTop = newScrollTop;
        }
      }
    };

    // Use requestAnimationFrame for smooth scrolling
    const animate = () => {
      if (isDragging) {
        handleScroll();
        scrollingRef.current = requestAnimationFrame(animate);
      }
    };
    scrollingRef.current = requestAnimationFrame(animate);

    return () => {
      // Clean up all event listeners
      if (mouseMoveListenerRef.current) {
        document.removeEventListener(
          "mousemove",
          mouseMoveListenerRef.current,
          { capture: true }
        );
        document.removeEventListener(
          "touchmove",
          mouseMoveListenerRef.current,
          { capture: true }
        );
      }
      if (scrollingRef.current) {
        cancelAnimationFrame(scrollingRef.current);
      }
    };
  }, [isDragging, mousePosition.y]);

  return (
    <div
      ref={scrollRef}
      style={{
        height: "calc(100vh - 60px)",
        overflow: "hidden auto",
        padding: "20px 0",
        position: "relative",
        scrollbarWidth: "none", // Firefox
        msOverflowStyle: "none", // IE/Edge
        "&::-webkit-scrollbar": {
          // Chrome/Safari/Opera
          display: "none",
        },
      }}
      onDragOver={(e) => {
        e.preventDefault();
        // Update mouse position during drag
        const mouseY = e.clientY || e.pageY;
        if (mouseY) {
          setMousePosition({ y: mouseY });
        }
      }}
    >
      {children}
    </div>
  );
};

const DNDDashboard = memo(
  ({ loading, sections, setSections, initialDashboardState }) => {
    const [isDragging, setIsDragging] = useState(false);
    const user = DecryptToken();
    const userType = Number(user?.user_type);

    const restrictedComponents = useMemo(
      () => [
        "aedsAssigned",
        "aedChecks",
        "DeliquentCheckHalfPieChart",
        "AedOwnedPieChart",
        "AedDistributionPieChart",
      ],
      []
    );

    const restrictedStats = useMemo(() => ["aedsAssigned", "aedChecks"], []);

    // if (userType === 0) {
    //   setSections((prevSections) => {
    //     const updatedSections = prevSections.map((section) => ({
    //       ...section,
    //       items: section?.items?.filter(
    //         (item) => !restrictedComponents.includes(item.id)
    //       ),
    //     }));
    //     return updatedSections;
    //   });
    // }

    // Memoize moveItem function
    const moveItem = useCallback(
      (
        dragIndex,
        hoverIndex,
        sourceSectionId,
        targetSectionId,
        draggedItem
      ) => {
        setSections((prevSections) => {
          const newSections = JSON.parse(JSON.stringify(prevSections));
          const sourceSection = newSections.find(
            (s) => s.id === sourceSectionId
          );
          const targetSection = newSections.find(
            (s) => s.id === targetSectionId
          );
          const [removed] = sourceSection.items.splice(dragIndex, 1);
          targetSection.items.splice(hoverIndex, 0, draggedItem || removed);
          updateDragDrpOrder(newSections);
          return newSections;
        });
      },
      []
    );

    // Memoize moveSection function
    const moveSection = useCallback((dragIndex, hoverIndex) => {
      setSections((prevSections) => {
        const newSections = [...prevSections];
        const [removed] = newSections.splice(dragIndex, 1);
        newSections.splice(hoverIndex, 0, removed);
        updateDragDrpOrder(newSections);
        return newSections;
      });
    }, []);

    // Memoize filtered sections
    // const filteredSections = useMemo(
    //   () =>
    //     userType === 0
    //       ? sections.filter((section) =>
    //           section?.items?.some(
    //             (item) => !restrictedComponents.includes(item.id)
    //           )
    //         )
    //       : sections,
    //   [sections, userType, restrictedComponents, restrictedStats]
    // );

    const filteredSections = useMemo(
      () =>
        userType === 0
          ? sections.filter((section) =>
              section?.items?.some(
                (item) => !restrictedComponents.includes(item.id)
              )
            )
          : userType === 3 && !user?.assign_aed
          ? sections.map((section) => ({
              ...section,
              items: section?.items?.filter((item) =>
                restrictedStats.includes(item.id)
              ),
            }))
          : sections,
      [sections, userType, restrictedComponents, restrictedStats]
    );

    if (userType === 0) {
      setSections((prevSections) => {
        const shouldUpdate = prevSections.some((section) =>
          section?.items?.some((item) => restrictedComponents.includes(item.id))
        );

        if (!shouldUpdate) return prevSections;

        return prevSections.map((section) => ({
          ...section,
          items: section?.items?.filter(
            (item) => !restrictedComponents.includes(item.id)
          ),
        }));
      });
    }


    return (
      <DndProvider backend={HTML5Backend}>
        <ScrollableContainer isDragging={isDragging}>
          <Container fluid>
            <div className="dashboard-sections">
              {filteredSections.map((section, index) => (
                // Number(checkLabelsLength(section.items) === 0) && (
                <div
                  key={section.id}
                  style={{
                    transition: "transform 0.3s ease",
                  }}
                >
                  {/* {JSON.stringify(section)} */}
                  <Section
                    section={section}
                    index={index}
                    sections={sections}
                    setSections={setSections}
                    moveItem={moveItem}
                    moveSection={moveSection}
                    loading={loading}
                    isDragging={isDragging}
                    setIsDragging={setIsDragging}
                  />
                </div>
              ))}
            </div>
          </Container>
        </ScrollableContainer>
      </DndProvider>
    );
  }
);

export default DNDDashboard;

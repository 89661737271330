import { DecryptToken } from "../../../../common/helper";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services";

export const findSectionById = (id, sections) => {
  // Loop through the sections array
  for (const section of sections) {
    // Check if the section's id matches the provided id
    if (section.id === id) {
      return section; // Return the matched section
    }

    // Loop through items in the section to find a matching item id
    for (const item of section.items) {
      if (item.id === id) {
        return item; // Return the matched item
      }
    }
  }

  return null; // Return null if no match is found
};

export const removeInvisibleItems = (data) => {
  return data
    .map((section) => {
      // Har section ke items ko filter kar rahe hain jaha isVisible == 1 hai
      const filteredItems = section.items.filter(
        (item) => item.isVisible === 1
      );

      // Return updated section object with filtered items
      return {
        ...section,
        items: filteredItems,
      };
    })
    .filter((section) => section.items.length > 0); // Agar kisi section me koi item nahi bacha to wo section bhi remove ho jaye
};

export const updateSections = (sections, permission) => {
  const idsToHide = {
    delinquentChecks: "postion",
    aedsOwned: "postion",
    aedDistribution: "postion",
    AedTechServicing: "aed_tech_service_check",
    aedTechAssignments: "aed_tech_assessment",
  };

  sections.forEach((section) => {
    section.items.forEach((item) => {
      // Handle idsToHide logic
      const permissionKey = idsToHide[item.id];
      if (permissionKey) {
        if (permission?.postion === false || permission?.postion === "false") {
          item.isVisible = 0;
        }
      }

      if (permissionKey) {
        if (
          item.id === "AedTechServicing" ||
          item.id === "aedTechAssignments"
        ) {
          const graphPermission = permission?.graph?.[0]?.[permissionKey];

          item.isVisible = graphPermission === "false" ? 0 : 1;
        }
      }
    });
  });

  return sections;
};

export const fetchDashboardCards1 = async () => {
  try {
    const res = await CallGETAPI("user/get-complete-user-account-information");
    return res?.data?.data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const fetchDashboardCards2 = async (params) => {
  try {
    const res = await CallGETAPI("user/user-assigned-aed-dashboard");
    return res?.data?.data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};
export const fetchDashboardPermission = async (params) => {
  try {
    const res = await CallGETAPI("user/fetch-dashboard-permission");
    return res?.data?.data;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const fetchPieChartData = async (sections, type, fetchtype) => {
  try {
    const res = await CallGETAPI("user/userdashborad-support-aed-information");
    const result = res.data.data;

    sections?.forEach((section) => {
      section.items?.forEach((item) => {
        if (item.id === fetchtype) {
          if (type === "aedsOwned") {
            item.data = result.aedOwned;
          }
          if (type === "aedDistribution") {
            item.data = result.aedDistribution;
          }
          if (type === "support") {
            item.data = result.Support;
          }
          if (type === "deliquentCheck") {
            item.data = result.deliquentCheck;
          } else {
            console.log("type missing", type);
          }
        }
      });
    });

    if (type === "aedsOwned") {
      return result?.aedOwned;
    }
    if (type === "aedDistribution") {
      return result?.aedDistribution;
    }
    if (type === "support") {
      return result?.Support;
    }
    if (type === "deliquentCheck") {
      return result?.deliquentCheck;
    } else {
      console.log("type missing in return", type);
    }
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};
export const fetchContact = async (sections, type, fetchtype) => {
  try {
    const res = await CallGETAPI("user/fetch-userdashborad-aed-tech");
    const result = res?.data?.data;

    return result;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const fetchAedTechBarGraphData = async (
  sections,
  type,
  fetchtype,
  payload
) => {
  try {
    const res = await CallPOSTAPI("user/aed-tech-service-check", payload);
    const result = res?.data?.data;

    sections?.forEach((section) => {
      section.items?.forEach((item) => {
        if (item.id === "AedTechServicing") {
          if (!Array.isArray(item.data)) {
            item.data = [];
          }
          // let response = Array.isArray(result) ? result : [result];
          // console.log({ response });
          // item.data = response;

          item.data.push(...(Array.isArray(result) ? result : [result]));
        }
      });
    });

    return result;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const fetchBarGraph = async (sections) => {
  try {
    const res = await CallGETAPI("user/fetch-aed-tech-assignment");
    const result = res?.data?.data;
    sections?.forEach((section) => {
      section.items?.forEach((item) => {
        if (item.id === "aedTechAssignments") {
          item.data = result;
        }
      });
    });

    return result;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const updateDragDrpOrder = async (updatedSections) => {
  try {
    const payload = {
      component: updatedSections,
    };
    const res = await CallPOSTAPI("user/assign-dashboard-component", payload);
  } catch (error) {
    console.error("Error updating dashboard on server:", error);
  }
};

export const supportPermission = (label) => {
  const user = DecryptToken();
  if (Number(user.user_type) === 0) {
    return 1;
  }
  if (Number(user.user_type) === 3) {
    if (
      label === "open_owned_ticket_count" ||
      label === "reponse_needed_ticket_count" ||
      label === "closed_ticket_count"
    ) {
      return 1;
    }
    return 0;
  }
};

export const checkPermissionById = (item, permission) => {
  const idsToHide = {
    delinquentChecks: "postion",
    aedsOwned: "postion",
    aedDistribution: "postion",
    AedTechServicing: "aed_tech_service_check",
    aedTechAssignments: "aed_tech_assessment",
  };

  const permissionKey = idsToHide[item.id];
  if (permissionKey) {
    if (item.id === "AedTechServicing" || item.id === "aedTechAssignments") {
      const graphPermission = permission?.graph?.[0]?.[permissionKey];
      return graphPermission === "false" ? 0 : 1;
    }

    return permission?.[permissionKey] === "false" ? 0 : 1;
  }
  return 1;
};

// Utility function to update section data
// export const updateSectionItemData = (
//   sections,
//   setSections,
//   targetId,
//   newData
// ) => {
//   const updatedSections = sections.map((section) => ({
//     ...section,
//     items: section.items?.map((item) =>
//       item.id === targetId ? { ...item, data: newData } : item
//     ),
//   }));
//   setSections(updatedSections);
// };

export const checkLabelsLength = (item) => {
  // Find the section containing the target id
  if (
    item.id === "aedsOwned" ||
    item.id === "aedDistribution" ||
    item.id === "delinquentChecks" ||
    item.id === "supportTickets" ||
    item.id === "AedTechServicing" ||
    item.id === "aedTechAssignments"
  ) {
    if (item?.data && item?.data?.length > 0) {
      return 0;
    } else {
      return 1;
    }
  }
  return 0;
};

export const showUser = (item) => {
  const user = DecryptToken();
  if (Number(user.user_type) === 0) {
    if (
      item.id === "aedsAssigned" ||
      item.id === "aedChecks" ||
      item.id === "delinquentChecks" ||
      item.id === "aedsOwned" ||
      item.id === "aedDistribution"
    ) {
      return 0;
    }
    return 1;
  }

  if (Number(user.user_type) === 3 && !user.assign_aed) {
    if (item.id === "aedsAssigned" || item.id === "aedChecks") {
      return 0;
    } else return 1;
  }

  return 1;
};
